<template>
  <main class="container my-3 text-justify">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mx-auto">
        <div class="col-12">
          <img
            class="img-fluid mx-auto d-block planet"
            src="@/assets/planet.svg"
            alt="planet"
          />
        </div>
        <div class="col-12 mt-4 mb-5">
          <h2>» Die Süss-Saure</h2>
          <p class="px-4">Ein kleines Universum.</p>
          <p class="raleway-bold px-4">
            Hier findet Ihr sämtliche Infos über die Süss-Saure. So ziemlich
            alles was relevant und interessant ist, haben wir hier für Euch
            aufgelistet.
          </p>
        </div>
        <div class="col-12 mt-5">
          <h2 class="m-0">
            » Alles Wichtige über
          </h2>
          <h2 class=" ps-4">
            die Süss-Saure
          </h2>
          <p class="mb-4 px-4">Was tun wir denn da rein?</p>
          <p class="px-4">
            Im wesentlichen, findest du vier verschiedene Komponenten in Deiner
            Flasche. Und zwar Rotwein, Weißwein Zitronenlimo und Wasser.
          </p>
        </div>
        <div class="col-12">
          <img
            class="img-fluid d-block mx-auto ingredients"
            src="@/assets/ingredients.svg"
            alt="ingredients"
          />
        </div>
        <div class="col-12 text-end">
          <img class="bottle pe-4" src="@/assets/team-bottle.svg" alt="team-bottle" />
        </div>
        <div class="col-12">
          <div class="card bg-dark round">
            <div class="card-body px-4">
              <h4 class="raleway-bold">Komm ins Team.</h4>
              <p>
                Exklusiv für euch, haben wir auf Spreadshirt besten
                Süss-SaureMerch erstellt. Kommt in unser Team und hüllt eure
                Körper in eines unserer wunderbaren Textilprodukte.
              </p>
              <p class="raleway-bold">Trage es mit Stolz.</p>
              <div class="text-center">
                <router-link to="/about-me" class="btn btn-primary shadow rounded-pill raleway-black">
                  » Zum Team
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
.mouse {
  height: 3rem;
}

.planet {
  height: 15rem;
}

.ingredients {
  height: 30rem;
}

.bottle {
  height: 5rem;
}
</style>
